<template>
  <div class="page-share">
    <img class="bg" src="~image/distribution/share/page-bg.png" />
    <div class="user">
      <div class="user-info">
        <img
          class="info-face"
          v-if="(shareData && shareData.face) || (!shareData && userInfo.face)"
          :src="(shareData && shareData.face) || (!shareData && userInfo.face)"
        />
        <app-icon v-else name="touxiang" size="98"></app-icon>
        <div class="info-wrap">
          <p class="info-name">
            {{ (shareData && shareData.name) || userInfo.name }}
          </p>
          <p class="info-termOfOffice">
            {{ shareData && shareData.isAgent ? '成为代理商' : '加入开彩乐'
            }}{{ (distributionData && distributionData.day) || 0 }}天
          </p>
        </div>
      </div>
      <div class="user-data">
        <div class="item">
          <p class="item-value">
            {{ (distributionData && distributionData.number) || 0 }}
          </p>
          <p>推广人数(人)</p>
        </div>
        <div class="item">
          <p class="item-value">
            {{ (distributionData && distributionData.totalPrice) || 0 }}
          </p>
          <p>{{ shareData && shareData.isAgent ? '总收益' : '奖励' }}(元)</p>
        </div>
      </div>
    </div>
    <div class="share">
      <p class="share-title">为什么选择开彩乐</p>
      <img
        class="share-reasons"
        src="~image/distribution/share/reasons-img.png"
      />
      <!-- TODO 二维码 -->
      <!-- <div class="share-qrcode">
        <img class="qrcode-img" :src="qrcodeImg" />
        <p class="qrcode-text">长按识别二维码<br />或手机扫描二维码加入我们</p>
      </div> -->
      <div class="share-btn" @click="handleBtnClick">
        {{ shareData ? '申请分销商' : '分享给朋友' }}
      </div>
      <p class="share-tip">支持中国公益彩票福利事业</p>
    </div>
    <share-cover :show.sync="coverShow"></share-cover>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import shareCover from './components/shareCover.vue';

export default {
  components: {
    shareCover
  },
  data() {
    return {
      coverShow: false,
      distributionData: null,
      shareData: null,
      qrcodeImg: `https://api.bornfortune.com/api/superior/qrcode?token=${localStorage.getItem(
        'token'
      )}`
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    getDistributionData() {
      return this.$http('user/superiorShare', {}, { loading: false }).then(
        res => {
          this.distributionData = res;
        }
      );
    },

    getQrcode() {
      this.$http('superior/qrcode', {}, { loading: false, dialog: false }).then(
        res => {
          this.qrcodeImg = res;
        }
      );
    },

    setLotteryShare() {
      const { WX_SHARE_URL } = this.$config;
      let shareData = {
        name: this.userInfo.name,
        face: this.userInfo.face,
        day: this.distributionData.day,
        number: this.distributionData.number,
        totalPrice: this.distributionData.totalPrice
      };
      let shareUrl = `${WX_SHARE_URL}distribution/share`;
      let parentCode = sessionStorage.getItem('parentCode') || '';
      shareUrl += `?shareData=${encodeURIComponent(JSON.stringify(shareData))}`;
      shareUrl += parentCode ? `&parentCode=${parentCode}` : '';

      return this.$wx.initShare({
        title: '注册邀请',
        desc: `${this.userInfo.name}邀请您成为分销商`,
        link: shareUrl,
        imgUrl: 'https://sparkapi.bornfortune.com/resource/image/logo.png'
      });
    },

    handleBtnClick() {
      if (!this.shareData) {
        this.coverShow = true;
      } else {
        this.$router.push('/user/register');
      }
    }
  },
  async created() {
    let { shareData } = this.$route.query;
    shareData = shareData ? JSON.parse(decodeURIComponent(shareData)) : null;
    if (!shareData) {
      try {
        this.$toast.loading({});
        await this.getDistributionData();
        this.$nextTick(() => {
          this.setLotteryShare();
        });
      } finally {
        this.$toast.clear();
      }
    } else {
      this.$wx.initShare();
      this.distributionData = {
        day: shareData.day,
        number: shareData.number,
        totalPrice: shareData.totalPrice
      };
      this.shareData = shareData;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-share {
  padding: 339px 0 0;
  background: linear-gradient(180deg, #f22d3c 0%, #a3000d 100%);

  .bg {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.user {
  margin: 0 auto;
  padding: 110px 0 0;
  width: 630px;
  height: 423px;
  background: url(~image/distribution/share/user-bg.png) 0 0 / cover no-repeat;

  &-info {
    @include align-center;
    margin-bottom: 43px;
    padding: 0 40px;

    .info {
      &-face {
        width: 98px;
        height: 98px;
        border-radius: 50%;
      }

      &-wrap {
        margin-left: 30px;
      }

      &-name {
        margin-bottom: 7px;
        font-size: 38px;
        font-weight: 500;
        color: #292929;
      }
    }
  }

  &-data {
    @include align-center;
    justify-content: space-around;

    .item {
      @include flex-center-center;
      flex-direction: column;
      font-size: 26px;
      color: #5f5f5f;

      &-value {
        margin-bottom: 6px;
        font-size: 78px;
        font-weight: 600;
        color: #ef2800;
      }
    }
  }
}

.share {
  padding: 41px 50px 0;
  width: 750px;
  min-height: calc(100vh - 762px);
  border-radius: 32px 32px 0px 0px;
  background: #fff;
  box-shadow: 0px -4px 30px 0px rgba(255, 163, 76, 0.4);

  &-title {
    @include flex-center-center;
    margin-bottom: 42px;
    font-size: 26px;
    color: #595959;

    &::after,
    &::before {
      content: '';
      display: block;
      width: 171px;
      height: 1px;
      background: #b9b9b9;
    }

    &::before {
      margin-right: 12px;
    }

    &::after {
      margin-left: 12px;
    }
  }

  &-reasons {
    margin-bottom: 43px;
    width: 100%;
  }

  &-qrcode {
    @include flex-center-center;
    margin-bottom: 38px;
    width: 650px;
    height: 211px;
    border-radius: 32px;
    border: 4px solid #ffbea4;

    .qrcode {
      &-img {
        margin-right: 40px;
        width: 160px;
        height: 160px;
      }

      &-text {
        line-height: 42px;
        font-size: 28px;
        font-weight: 500;
        color: #828282;
      }
    }
  }

  &-btn {
    @include flex-center-center;
    margin: 0 auto 0;
    width: 630px;
    height: 98px;
    border-radius: 24px;
    font-size: 36px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
    box-shadow: 0px 8px 0px 0px #ffc6b5;
  }

  &-tip {
    margin-top: 36px;
    padding-bottom: 30px;
    text-align: center;
    letter-spacing: 22px;
    color: #aaa;
  }
}
</style>
