<template>
  <div class="page-withdrawal">
    <div class="withdrawal-data">
      <div class="header">
        <span>可提现佣金(元)</span>
        <router-link class="header-link" to="/distribution/withdrawalLog"
          >提现记录</router-link
        >
      </div>
      <div class="content">
        <span>¥</span>
        <span class="content-value">{{ userInfo.price }}</span>
      </div>
    </div>
    <div class="withdrawal-form">
      <div class="form-tabs">
        <div
          :class="`form-tab${curTab === 'wallet' ? '_active' : ''}`"
          @click="changeCurTab('wallet')"
        >
          <app-icon
            class="form-tabIcon"
            :name="`withdrawal-money${curTab === 'wallet' ? '_active' : ''}`"
            size="32"
          ></app-icon>
          <span>账户余额</span>
        </div>
        <div
          :class="`form-tab${curTab === 'bankCard' ? '_active' : ''}`"
          @click="changeCurTab('bankCard')"
        >
          <app-icon
            class="form-tabIcon"
            :name="`withdrawal-card${curTab === 'bankCard' ? '_active' : ''}`"
            size="32"
          ></app-icon>
          <span>银行卡</span>
        </div>
      </div>
      <template v-for="(item, key) in inputListObj">
        <div
          class="form-item"
          :key="key"
          v-show="!item.ofTab || item.ofTab === curTab"
        >
          <input
            v-model="item.value"
            class="form-input"
            type="text"
            :inputmode="item.inputmode"
            :placeholder="item.placeholder"
            @input="changeInputCloseShow(key)"
            @focus="changeInputCloseShow(key)"
            @blur="handleInputBlur(key)"
          />
          <app-icon
            class="form-inputClose"
            name="close"
            size="38"
            v-show="item.closeShow"
            @click.native="handleInputCloseClick(key)"
          ></app-icon>
        </div>
      </template>
    </div>
    <div :class="`withdrawal-tip${tipShow ? '_show' : ''}`">
      <div class="tip-header" @click="tipShow = !tipShow">
        <app-icon class="tip-icon" name="wenhao" size="28"></app-icon>
        <p>《提现须知》</p>
      </div>
      <div class="tip-content">
        <p class="tip-title">开彩乐平台《提现须知》：</p>
        <p class="tip-item">
          <span class="tip-bold">一、对于分销商用户：</span>
          <br />
          分销商用户申请佣金时，需提交银行卡信息，开彩乐平台收到提现申请后，将在3个工作日内进行审核，7个工作日内完成打款。
        </p>
        <p class="tip-item">
          <span class="tip-bold">二．对于代理商用户：</span>
          <br />
          代理商用户申请佣金时，需向开彩乐平台开具推广服务费发票（请按照平台补贴佣金金额开具发票），收到发票经我司财务人员核验无误后7个工作日内完成公对公打款。
        </p>
        <div class="tip-btn" @click="tipShow = false">收起</div>
      </div>
    </div>
    <div class="withdrawal-btn" @click="handlewithdrawalBtnClick">申请提现</div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      inputListObj: {
        bankCardName: {
          value: '',
          inputmode: 'text',
          placeholder: '请输入银行卡用户名',
          closeShow: false,
          ofTab: 'bankCard'
        },
        bankCardNum: {
          value: '',
          inputmode: 'numeric',
          placeholder: '请输入银行卡号',
          closeShow: false,
          ofTab: 'bankCard'
        },
        money: {
          value: '',
          inputmode: 'decimal',
          placeholder: '请输入提现金额',
          closeShow: false,
          ofTab: ''
        }
      },
      tipShow: false,
      // wallet: 账户余额; bankCard: 银行卡
      curTab: 'wallet',
      value: ''
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    ...mapMutations({ updateUserPrice: 'user/updateUserPrice' }),

    changeCurTab(val) {
      if (val === this.curTab) return;
      let { inputListObj: obj } = this;
      for (const key in obj) {
        obj[key].value = '';
      }
      this.curTab = val;
    },

    changeInputCloseShow(key = '') {
      let item = this.inputListObj[key];
      item.closeShow = !!item.value;
    },

    handleInputBlur(key = '') {
      let item = this.inputListObj[key];
      item.value = item.value.trim();
      setTimeout(() => {
        item.closeShow = false;
      }, 10);
    },

    handleInputCloseClick(key = '') {
      let item = this.inputListObj[key];
      item.value = '';
      item.closeShow = false;
    },

    verification() {
      let { inputListObj: obj } = this;
      let money = Number(obj.money.value);
      switch (this.curTab) {
        case 'bankCard':
          if (!obj.bankCardName.value) {
            return '请输入银行卡用户名';
          }
          if (!obj.bankCardNum.value) {
            return '请输入银行卡号';
          }
          break;
      }
      if (money === 0) {
        return '请输入提现金额';
      }
      if (money !== Number(obj.money.value)) {
        return '请输入正确的提现金额';
      }
      if (money > this.userInfo.price) {
        return '提现金额不能大于可提现佣金';
      }
      return '';
    },

    handlewithdrawalBtnClick() {
      let verify = this.verification();
      if (verify) return this.$toast(verify);
      let { inputListObj: obj } = this;
      let data = {
        price: Number(obj.money.value),
        scene: this.curTab
      };
      switch (data.scene) {
        case 'bankCard':
          data.name = obj.bankCardName.value;
          data.cardNumber = obj.bankCardNum.value;
          break;
      }
      this.$http('cash/apply', data, { dialog: false })
        .then(res => {
          this.value = '';
          this.updateUserPrice(Number(res));
          this.$toast.success('提交申请成功');
          for (const key in obj) {
            obj[key].value = '';
          }
        })
        .catch(err => {
          try {
            let errData = JSON.parse(err.message);
            this.$dialog.alert({
              message: errData.message
            });
          } catch {
            let errMsg = err.message;
            this.$dialog.alert({
              message: errMsg
            });
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-withdrawal {
  padding: 0 0 190px;
  min-height: 100vh;
}

.withdrawal {
  &-data {
    padding: 45px 60px 0;
    width: 750px;
    height: 320px;
    background: linear-gradient(180deg, #ff925e 0%, #ff4019 100%);
    color: #fff;

    .header {
      @include flex-between-center;
      margin-bottom: 12px;
      font-size: 28px;

      &-link {
        font-size: 28px;
        color: #fff;
      }
    }

    .content {
      font-size: 38px;
      font-weight: 500;

      &-value {
        margin-left: 12px;
        font-size: 52px;
      }
    }
  }

  &-form {
    margin: -108px auto 0;
    padding: 40px 30px 40px;
    width: 690px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.24);

    .form {
      &-tabs {
        @include align-center;
        margin-bottom: 40px;
      }

      &-tab {
        @include align-center;
        margin-right: 33px;
        padding-left: 20px;
        width: 188px;
        height: 88px;
        border-radius: 16px;
        font-size: 28px;
        color: #4d4d4d;
        background: #f5f5f5;

        &_active {
          @extend .form-tab;
          color: #fff;
          background: #4983ff;
        }
      }

      &-tabIcon {
        margin-right: 10px;
      }

      &-item {
        @include align-center;
        margin-bottom: 30px;
        padding-right: 20px;
        width: 100%;
        height: 98px;
        border-radius: 24px;
        background: #f9f9f9;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-input {
        padding: 0 30px;
        flex: 1;
        height: 100%;
        font-size: 30px;
      }
    }
  }

  &-tip {
    height: 96px;
    overflow: hidden;
    transition: all 0.3s;

    &_show {
      @extend .withdrawal-tip;
      height: 620px;
    }

    .tip {
      &-header {
        @include align-center;
        padding: 28px 30px 50px;
        font-size: 28px;
        color: #3978e3;
      }

      &-icon {
        margin-top: 4px;
      }

      &-content {
        padding: 0 40px;
      }

      &-title {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      &-item {
        line-height: 42px;
        font-size: 24px;
        color: #989898;
      }

      &-bold {
        color: #1e1e1e;
      }

      &-btn {
        padding-bottom: 4px;
        margin-top: 50px;
        width: 74px;
        font-size: 28px;
        color: #5d5d5d;
        border-bottom: 3px solid #5d5d5d;
      }
    }
  }

  &-btn {
    @include flex-center-center;
    position: absolute;
    bottom: 60px;
    left: 60px;
    width: 630px;
    height: 98px;
    border-radius: 24px;
    font-size: 36px;
    font-weight: 500;
    color: #fff;
    background: #fb9938 linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
    box-shadow: 0px 8px 0px 0px #ffc6b5;
  }
}
</style>
