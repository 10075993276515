<template>
  <div class="page-about">
    <p class="title">分销商经营规范</p>
    <p class="text">
      开彩乐是海口龙华区京蛰软件开发工作室官方直营的付费内容平台，为保证良好的用户体验和维护行业健康发展,维护公平竞争、维护用户的权益及业务的合规合法,在严格遵守国家相关法律法规基础之上,平台出台以下管理规范,各位分销商在进行经营和宣传时务必查看详细规则,确保不存在违规违法内容。
      <br />
      <br />
      <br />
    </p>
    <p class="subtitle">一、遵守法律法规</p>
    <p class="text">
      遵守国家相关法律法规,严厉禁止以涉嫌传销、欺骗等非法手段导、强迫他人注册加入。<br />
      <br />
    </p>
    <p class="subtitle">二、遵守用词规范要求(包括但不限于此)</p>
    <p class="text">
      1、禁止使用以下敏感词汇上/下线、上/下级、躺赚、多级分佣、多级分销、等级、晋级、顶级、拉人、贡献、发展下级/下线、发展会员、微信机器人/机器人、国家级、世界级、最高级、首个、顶级、终对、首家、最赚、最先、著名、至尊享受、全国驰名、全球驰名等绝对性用语;国家XXX领导人推荐、国家ⅩXX机关推荐、国家ⅩXX机关专供、特供等借国家、国家机关工作人员名称进行宣传的用语；<br />
      2、建议使用以下词汇： <br />
      邀请、推荐、直属、直接、间接、智能助理(小助理)、团队等正确词汇;、遵守推广宣传规范准则(包括但不限于此)；
    </p>
    <p class="explain">
      1. 严厉禁止利用刷单、刷券、联合骗取补贴等不正当方式牟取利益;<br />
      2.严厉禁止诱导本平台分销商经营其它平台;<br />
      3.严厉禁止进行虚假的页面涂改、虚假截图引用，及夸大宣传;<br />
      4.严厉禁止对平台的负面宣导;<br />
      5.严厉禁止向团队会员收取任何费用，包括但不限于培训费、服务费、介绍费、押金、压金、保证金等;<br />
      6. 严厉禁止到开彩乐的其它团队推广群进行推广宣传;<br />
      7.严厉禁止用推广链接或邀请码去做涉政、黄赌毒P2P等业务的引流和恶意诱导分享;<br />
      8.严厉禁止对外使用国家领导人及干部、明星、开彩乐创始人及其家人的形象、签名做推广;<br />
      9.严厉禁止未经授权使用公司相关品牌形象、商标,包括但不限于版权、logo、公司标识标志等进行推广宣传;<br />
      10.严厉禁止在未经开彩乐官方授权或宣传内容未经核准的情况下，通过公共媒体投放广告,包括但不限于户外、电视、广播、电梯等形式;<br />
      11.严厉禁止以开彩乐官方、开彩乐高管、开彩乐官方开彩乐高管名义捏造、散布谣言或不实的言论信息，禁止篡改、伪造官方资讯,损害开彩乐、开彩乐形象，扰乱社群秩序;<br />
      12. 严厉禁止未经授权使用他人原创内容进行推广宣传，侵犯他人知识产权;<br />
      13.严厉禁止收集利用团队成员的身份证号等个人信息等谋取不正当利益，或以推广为名义变相收集他人个人信息谋取不正当利益,侵犯他人合法权益;<br />
      14.严厉禁止分销商之间采取恶意诋毁、返现诱导、虚假宣传等不公平、不正当手段吸引、招揽分销商或消费者，禁止诋毁、排斥其他分销商达到获利等不道德行为;<br />
      15.严厉禁止在百度、头条、微信、公众号、朋友圈、微博、抖音、快手等渠道发布有可能影晌开彩乐或开彩乐商誉的文章或言论等，如：“开彩乐黑幕揭秘”；“开彩乐是传销吗?”;<br />
      16.严厉禁止在社交媒体(微信朋友圈、微博、百度、贴吧、抖音、快手等)发布或虚构开彩乐规则、奖励制度等相关信息;
      <br />
      <br />
      <br />
    </p>
    <p class="subtitle">三、违规处理标准</p>
    <p class="text">
      本管理规范从公布之日起开始实行，一经发现上述明令禁止的行为，视情节轻重按照以下规定予以处理：<br />
      1、警告：首次违规，视违规情节程度给予警告;<br />
      2、封号(并扣除所有佣金/补贴)：违规情节严重;<br />
      3、违反国家有关法律法规涉嫌犯罪的，依法移送司法机关处理。同时，开彩乐将保留进一步追究相关法律责任的权利。<br />
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
.page-about {
  padding: 65px 30px 53px;
  line-height: 42px;
  font-size: 26px;
  color: #4c4c4c;
}

.title {
  margin-bottom: 30px;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  color: #292929;
}

.subtitle {
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 500;
  color: #191919;
}

.explain {
  margin-top: 10px;
  font-size: 24px;
  color: #717171;
}
</style>
