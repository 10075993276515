<template>
  <!-- 分销数据 -->
  <div class="page-distributionData" v-if="initialized">
    <form
      action=""
      class="search"
      @submit.prevent="handleSearchTextChange($event)"
    >
      <app-icon class="search-icon" name="search" size="42"></app-icon>
      <input
        class="search-input"
        type="text"
        placeholder="搜索我的分销商"
        v-model="searchText"
        enterkeyhint="search"
      />
      <app-icon
        v-if="searchText"
        class="search-clear"
        name="close"
        size="30"
        @click.native="handleSearchTextClear"
      ></app-icon>
    </form>
    <div class="inviter" v-if="inviterData">
      <p class="inviter-title">邀请我的</p>
      <div class="inviter-info">
        <img class="inviter-face" :src="inviterData.face" />
        <span class="inviter-name">{{ inviterData.name }}</span>
      </div>
    </div>
    <div class="distribution">
      <p class="distribution-title">
        <span>我邀请的 ({{ distributionData.total || 0 }})</span>
        <span class="distribution-time"
          >数据截至
          {{ distributionData.time | dateFormat('YYYY.MM.DD HH:mm:ss') }}</span
        >
      </p>
      <!-- TODO 列表筛选 -->
      <!-- <div class="distribution-tools">
        <div class="distribution-filter">
          <p class="filter-value">按最新注册</p>
          <div class="filter-list">
            <p class="filter-item_active">按最新注册</p>
            <p class="filter-item">按最高收益</p>
          </div>
        </div>
        <p class="distribution-help">
          <app-icon
            class="distribution-help_icon"
            name="help"
            size="31"
          ></app-icon>
          <span>数据说明</span>
        </p>
      </div> -->
      <div
        class="distribution-item"
        v-for="item in distributionData.list"
        :key="item.id"
      >
        <div class="distribution-content">
          <img v-if="item.face" :src="item.face" class="content-face" />
          <app-icon
            v-else
            class="content-face"
            name="touxiang"
            size="98"
          ></app-icon>
          <div class="content-info">
            <p class="content-name">{{ item.userName }}</p>
            <p class="content-phone">手机号：{{ item.phone }}</p>
            <p class="content-registerTime">
              {{ item.time | dateFormat('YYYY.MM.DD') }}
              注册
            </p>
          </div>
          <p class="content-profit">
            <span>我的所得收益</span>
            <br />
            <span>¥ {{ item.profit }}</span>
          </p>
        </div>
        <div class="distribution-data">
          <div class="data-item">
            <p class="data-label">本月本人客户数(人)</p>
            <p class="data-value">{{ item.clientTotal }}</p>
          </div>
          <div class="data-item">
            <p class="data-label">本月本人销售额(元)</p>
            <p class="data-value">{{ item.saleVolume }}</p>
          </div>
        </div>
      </div>
      <p class="distribution-empty">暂无数据</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: '',
      initialized: false,
      inviterData: null,
      distributionData: {
        time: 0,
        total: 0,
        list: []
      }
    };
  },
  methods: {
    getDistributionData() {
      this.$http('user/superiorList', {
        page: 1,
        pageSize: 999,
        name: this.searchText
      }).then(res => {
        let { distributionData: data } = this;
        if (res.parent) {
          this.inviterData = {
            face: res.parent.avatarUrl,
            name: res.parent.nickName
          };
        }
        data.time = res.time * 1000;
        data.total = res.total;
        data.list = res.data.map(e => {
          return {
            id: e.id,
            userName: e.nickName,
            face: e.avatarUrl,
            phone: e.phone || '暂无',
            time: e.superiorTime * 1000,
            clientTotal: e.underInfo.count,
            saleVolume: e.underInfo.amount,
            profit: e.underInfo.parentIncome
          };
        });
        this.initialized = true;
      });
    },

    handleSearchTextClear() {
      this.searchText = '';
      this.getDistributionData();
    },
    handleSearchTextChange() {
      document.activeElement.blur();
      this.getDistributionData();
    }
  },
  created() {
    this.getDistributionData();
  }
};
</script>

<style lang="scss" scoped>
.page-distributionData {
  padding: 20px 30px 90px;
}
.search {
  @include align-center;
  @include hairlines(all, #e0e0e0, 16px);
  margin: 0 auto 20px;
  padding: 0 50px 0 30px;
  width: 690px;
  height: 88px;
  background: #fff;

  &-icon {
    margin-right: 30px;
  }

  &-input {
    flex: 1;
    margin-right: 20px;
    font-size: 28px;
  }

  &-clear {
    margin-right: -30px;
  }
}

.inviter {
  margin-bottom: 40px;

  &-title {
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 500;
  }

  &-info {
    @include align-center;
    padding: 40px 30px;
    width: 100%;
    border-radius: 16px;
    font-size: 26px;
    color: #666;
    background: url(~image/distribution/distributionData/inviter-bg.png) 0 0 /
      cover no-repeat;
  }

  &-face {
    margin-right: 30px;
    width: 98px;
    height: 98px;
    border-radius: 50%;
  }

  &-name {
    margin-bottom: 10px;
    font-size: 32px;
    font-weight: 500;
    color: #333;
  }
}

.distribution {
  &-title {
    @include flex-between-center;
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 500;
  }

  &-time {
    font-size: 22px;
    font-weight: 400;
    color: #999;
  }

  &-tools {
    @include flex-between-center;
    margin-bottom: 33px;
  }

  &-filter {
    font-size: 28px;
    color: #666;

    .filter {
      &-value {
        @include align-center;

        &::after {
          content: '';
          display: block;
          margin-left: 15px;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-top-color: #666;
          border-bottom-width: 0;
        }
      }

      &-list {
        z-index: 1;
        position: absolute;
        left: 0;
        bottom: -166px;
        padding: 0 20px;
        width: 182px;
        height: 160px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.22);
      }

      &-item {
        @include flex-center-center;
        width: 100%;
        height: 50%;

        &:first-of-type {
          @include hairlines(bottom #e6e6e6);
        }

        &_active {
          @extend .filter-item;
          color: #ff775c;
        }
      }
    }
  }

  &-help {
    @include align-center;
    color: #999;
    &_icon {
      margin-right: 10px;
    }
  }

  &-item {
    margin-bottom: 40px;
    padding: 40px 30px 30px;
    width: 690px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.22);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-content {
    @include hairlines(bottom, #ccc);
    display: flex;
    padding-bottom: 30px;

    .content {
      &-face {
        margin-right: 30px;
        width: 98px;
        height: 98px;
        border-radius: 50%;
      }

      &-info {
        flex: 1;
        margin-right: 30px;
        font-size: 26px;
        color: #666;
      }

      &-name {
        margin-bottom: 10px;
        font-size: 32px;
        font-weight: 500;
        color: #333;
      }

      &-phone {
        margin-bottom: 10px;
      }

      &-profit {
        line-height: 34px;
        text-align: right;
        color: #ff775c;
      }
    }
  }

  &-data {
    @include flex-wrap;
    width: 100%;

    .data {
      &-item {
        margin-top: 30px;
        width: 50%;
        color: #808080;
      }

      &-value {
        margin-top: 10px;
        font-size: 36px;
        color: #4d4d4d;
      }
    }
  }

  &-empty {
    margin-top: 40px;
    text-align: center;
    font-size: 28px;
    color: #ccc;
  }
}
</style>
