<template>
  <!-- 直推数据 -->
  <div class="promote" v-if="initialized">
    <div class="promote-header">
      <img
        class="header-img"
        src="~image/distribution/promoteData/promote-data_bg.png"
      />
      <div class="header-title">
        <p>整体数据</p>
        <p class="promote-time">
          数据截至 {{ promoteData.time | dateFormat('YYYY.MM.DD HH:mm:ss') }}
        </p>
      </div>
    </div>
    <div class="promote-data">
      <div class="data-item">
        <app-icon class="data-icon" name="promote-kehu" size="88"></app-icon>
        <div class="data-wrap">
          <p>客户总数(个)</p>
          <p class="data-value">{{ promoteData.browseTotal }}</p>
        </div>
      </div>
      <div class="data-item">
        <app-icon class="data-icon" name="promote-shouyi" size="88"></app-icon>
        <div class="data-wrap">
          <p>我的收益(元)</p>
          <p class="data-value">{{ promoteData.payTotal }}</p>
        </div>
      </div>
    </div>
    <div class="promote-list">
      <van-tabs
        v-model="curTabIdx"
        class="promote-tabs"
        @change="handleTabsChange"
      >
        <van-tab title="普通订单"></van-tab>
        <van-tab title="服务订单"></van-tab>
      </van-tabs>
      <div class="promote-item" v-for="item in promoteData.list" :key="item.id">
        <div class="item-header">
          <img class="item-face" :src="item.face" v-if="item.face" />
          <app-icon
            class="item-face"
            name="touxiang"
            size="68"
            v-else
          ></app-icon>
          <p class="item-name">
            <span>{{ item.userName }}</span>
          </p>
          <p class="item-time">
            {{ item.time | dateFormat('YYYY.MM.DD HH:mm:ss') }}
          </p>
        </div>
        <p class="item-id">
          <span>交易单号：</span>
          <span>{{ item.orderCode }}</span>
        </p>
        <p class="item-info">
          <span>购买内容：</span>
          <span class="item-info_value">{{ item.content }}</span>
        </p>
        <p class="item-info">
          <span>支付金额：</span>
          <span class="item-info_value">¥ {{ item.price }}</span>
        </p>
        <p class="item-info">
          <span>所得佣金：</span>
          <span class="item-info_value">¥ {{ item.profit }}</span>
        </p>
      </div>
      <p v-if="!promoteData.list.length" class="promote-empty">暂无数据</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: '',
      curTabIdx: 0,
      promoteData: {
        list: [],
        time: 0,
        browseTotal: 0,
        payTotal: 0,
        profitTotal: 0
      },
      initialized: false
    };
  },
  methods: {
    getPromoteData() {
      this.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      Promise.all([
        this.getPromoteCountData({ loading: false, dialog: false }),
        this.getPromoteList({ loading: false, dialog: false })
      ])
        .then(() => {
          this.initialized = true;
        })
        .catch(err => {
          let errData = JSON.parse(err.message);
          this.$dialog.alert({
            message: `页面初始化失败，请联系管理员！\n错误信息：${errData.message}`
          });
        })
        .finally(() => {
          this.$toast.clear();
        });
    },

    getPromoteCountData(config = {}) {
      return this.$http('user/pushCount', {}, config).then(res => {
        let { promoteData } = this;
        promoteData.time = res.time * 1000;
        promoteData.browseTotal = res.clientCount;
        promoteData.payTotal = res.incomeCount;
      });
    },

    getPromoteList(config = {}) {
      return this.$http(
        'user/pushList',
        {
          type: this.curTabIdx ? 'service' : 'order',
          page: 1,
          pageSize: 999
        },
        config
      ).then(res => {
        this.promoteData.list = res.data.map(e => {
          return {
            id: e.id,
            userName: e.user.nickName,
            face: e.user.avatarUrl,
            time: e.payTime * 1000,
            orderCode: e.orderNo,
            price: Number(e.price) || 0,
            profit: Number(e.firstPrice) || 0,
            content: this.curTabIdx
              ? `开彩乐-${e.serviceName}会员`
              : `付款预测(排列五：第${e.no}期)`
          };
        });
      });
    },

    handleTabsChange() {
      this.getPromoteList();
    }
  },
  created() {
    this.getPromoteData();
  }
};
</script>

<style lang="scss" scoped>
.promote {
  &-header {
    @include flex-between-center;
    margin-bottom: 30px;
    padding: 42px 30px 0;

    .header {
      &-img {
        width: 244px;
        height: 108px;
      }

      &-title {
        font-size: 42px;
        font-weight: 600;
        text-align: right;
        color: #070707;
      }
    }
  }

  &-time {
    margin-top: 18px;
    font-size: 22px;
    font-weight: 400;
  }

  &-data {
    display: flex;
    padding: 34px 30px 0;
    width: 100%;
    height: 256px;
    border-radius: 32px 32px 0 0;
    background: #fd8d00;

    .data {
      &-item {
        display: flex;
        flex: 1;
        text-align: center;
        color: #808080;
      }

      &-icon {
        margin: 8px 20px 0 0;
      }

      &-wrap {
        font-size: 26px;
        color: #fff;
      }

      &-value {
        margin-top: 4px;
        font-size: 52px;
        font-weight: 500;
        text-align: left;
      }
    }
  }

  &-list {
    margin-top: -84px;
    padding: 40px 30px 50px;
    width: 100%;
    border-radius: 32px 32px 0px 0px;
    background: #fff;
  }

  &-tabs {
    margin: 0 auto 40px;
    padding: 5px 10px;
    width: 690px;
    height: 98px;
    border: 2px solid #ff7b5a;
    border-radius: 49px;
    overflow: hidden;

    &::v-deep {
      .van-tabs__line {
        bottom: 36px;
        width: 334px;
        height: 78px;
        border-radius: 39px;
        background: linear-gradient(90deg, #ff7b5a 0%, #ff182f 100%);
      }

      .van-tab {
        z-index: 2;
        font-size: 30px;
        font-weight: 500;
        color: #ff2836;

        &--active {
          color: #fff;
        }
      }
    }
  }

  &-item {
    margin-bottom: 40px;
    padding: 30px 30px 40px;
    width: 690px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 28px 0px rgba(153, 153, 153, 0.24);

    &:last-of-type {
      margin-bottom: 0;
    }

    .item {
      &-header {
        @include align-center;
        @include hairlines(bottom, #ccc);
        margin-bottom: 30px;
        padding-bottom: 30px;
      }

      &-face {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        margin-right: 30px;
      }

      &-name {
        width: 311px;
        font-size: 32px;
        font-weight: 500;
        &_suffix {
          margin-left: 30px;
        }
      }

      &-time {
        font-size: 22px;
        color: #999;
      }

      &-info {
        @include flex-between-center;
        margin-bottom: 30px;
        font-size: 28px;
        color: #999;

        &:last-of-type {
          margin-bottom: 0;
        }

        &_value {
          color: #4d4d4d;
        }
      }

      &-id {
        @extend .item-info;
        color: #333;
      }
    }
  }

  &-empty {
    font-size: 28px;
    text-align: center;
    color: #ccc;
  }
}
</style>
